// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border {
    position: relative; /* Ensure the parent element is relatively positioned */
  }
  
  .border::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 11px; /* Width of the border */
    background: linear-gradient(180deg, #AEB4B7 39.5%, #F9F9F9 100%);
  }
  `, "",{"version":3,"sources":["webpack://./src/presentational/VisitCard/Content/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,uDAAuD;EAC7E;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,WAAW,EAAE,wBAAwB;IACrC,gEAAgE;EAClE","sourcesContent":[".border {\n    position: relative; /* Ensure the parent element is relatively positioned */\n  }\n  \n  .border::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    width: 11px; /* Width of the border */\n    background: linear-gradient(180deg, #AEB4B7 39.5%, #F9F9F9 100%);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
