// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer{-ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

.drawer::-webkit-scrollbar{
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Drawer/index.css"],"names":[],"mappings":"AAAA,QAAQ,mCAAmC,GAAG,gBAAgB;EAC5D,gCAAgC,GAAG,YAAY;AACjD;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".drawer{-ms-overflow-style: none !important;  /* IE and Edge */\n  scrollbar-width: none !important;  /* Firefox */\n}\n\n.drawer::-webkit-scrollbar{\n  display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
