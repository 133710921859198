// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text:after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0 #201f1f;
  content: "";
  display: block;
  height: 1px;
  width: 0;
}
.text-selected:after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0 #201f1f;
  content: "";
  display: block;
  height: 1px;
  width: 100%;
}
.text:hover:after {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/MainLayout/Header/index.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,0CAA0C;EAC1C,WAAW;EACX,cAAc;EACd,WAAW;EACX,QAAQ;AACV;AACA;EACE,gCAAgC;EAChC,0CAA0C;EAC1C,WAAW;EACX,cAAc;EACd,WAAW;EACX,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".text:after {\n  transition: all ease-in-out 0.2s;\n  background: none repeat scroll 0 0 #201f1f;\n  content: \"\";\n  display: block;\n  height: 1px;\n  width: 0;\n}\n.text-selected:after {\n  transition: all ease-in-out 0.2s;\n  background: none repeat scroll 0 0 #201f1f;\n  content: \"\";\n  display: block;\n  height: 1px;\n  width: 100%;\n}\n.text:hover:after {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
