// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  border: solid 2px black;
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Incrementor/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".box {\n  border: solid 2px black;\n  width: 35px;\n  height: 35px;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
